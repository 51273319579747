const badPhrasesList = [
    'carpet muncher',
    'f u c k',
    'flipping the bird',
    'fudge packer',
    'god damned',
    'god-damned',
    'penis-breath',
    'drunk retard',
];

const identifyBadPhrases = (text: string) => {
    const badPrasesInText = badPhrasesList.filter(
        (phrase) => text.toLowerCase().indexOf(phrase) !== -1,
    );
    return badPrasesInText;
};

export default identifyBadPhrases;
