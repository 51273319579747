export const avatarBackgroundColors = [
    '#29EFCC',
    '#81EA91',
    '#92DEFF',
    '#A7F247',
    '#A7F9BE',
    '#A9DDB3',
    '#B0BFF3',
    '#B5FBFF',
    '#C1A2F0',
    '#CBA7F9',
    '#CBD3E6',
    '#D3E75E',
    '#DCFF92',
    '#E7EEF5',
    '#EA9DA3',
    '#F19F9E',
    '#F9B550',
    '#FCB97C',
    '#FDD891',
    '#FF87A4',
    '#FFBADF',
];
