export const COUNT = {
    VOTES: 'count:votes',
};

export const ROOM = {
    JOIN: 'room:join',
    LEAVE: 'room:leave',
    ADD: 'room:add',
    CLEAR: 'room:clear',
    CLEARED: 'room:cleared',
    UPDATE_ACTIVITY_ROUND: 'room:update_activity_round',
    UPDATED_ACTIVITY_ROUND: 'room:updated_activity_round',
    CURRENT_ACTIVITY_ROUND: 'room:current_activity_round',
    CHECK_EXISTING: 'room:check_existing_room',
};

export const PARTICIPANT = {
    COUNT: 'participant:count',
    REMOVE: 'participant:remove',
    REMOVED: 'participant:removed',
    BR_TEAM_UPDATED: 'participant:battle_royale_team_updated',
};

export const QUESTION = {
    ADDED: 'question:added',
    EDIT: 'question:edit',
    EDITED: 'question:edited',
    DELETE: 'question:delete',
    DELETED: 'question:deleted',
    GET_ACTIVE: 'question:get_active',
    // When a question changed to is_active = true by the user
    LAUNCHED: 'question:launched',
    GET_ALL: 'question:get_all',
    GET_AVG_RESPONSE_CONFIDENCE: 'question:get_avg_response_confidence',
    // Generic channel, causes the client to refetch a question in QuestionContext
    UPDATED: 'question:updated',
};

export const RESPONSE = {
    ADDED: 'response:added',
    DELETED: 'response:deleted',
    VOTE: 'response:vote',
    VOTED: 'response:voted',
    GET_ALL: 'response:get_all',
    GET_SELECTED: 'response:get_selected',
    UPDATE_SELECTED: 'response:update_selected',
    UPDATED_SELECTED: 'response:updated_selected',
    UPDATE_SCORES: 'response:update_scores',
    UPDATED_SCORES: 'response:updated_scores',
    REQUEST_TEACHER_REVIEW: 'response:request_teacher_review',
};

export const FEEDBACK = {
    ADD: 'feedback:add',
    ADDED: 'feedback:added',
    AGGREGATE: 'feedback:aggregate',
};

export const REFLECTION = {
    ADD: 'reflection:add',
    ADDED: 'reflection:added',
};

// channels to update the participant_response_vote table
export const PR_VOTE = {
    ADD: 'pr_vote:add',
    ADDED: 'pr_vote:added',
};

export const SESSION = {
    INITIALIZE: 'session:initialize',
    CHECK: 'session:check',
    JOIN: 'session:join',
    ADD: 'session:add',
    ADDED: 'session:added',
    UPDATED: 'session:updated',
    GET_ACTIVE: 'session:get_active',
    UPDATE_ACTIVITY_ROUND: 'session:update_activity_round',
    UPDATED_ACTIVITY_ROUND: 'session:updated_activity_round',
    CURRENT_ACTIVITY_ROUND: 'session:current_activity_round',
    ENDED: 'session:ended',
    LEAVE: 'session:leave',
};

export const AI_FEEDBACK = {
    GENERATED: 'ai_feedback:generated',
};
