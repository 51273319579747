export const subjectsList = [
    'Physical Education',
    'Art',
    'Music',
    'Computer Science',
    'Foreign language',
    'Pre-Algebra',
    'Algebra I',
    'Geometry',
    'Math',
    'English Language Arts',
    'Science',
    'Social Studies',
    'Algebra II',
    'Trigonometry',
    'Calculus',
    'Statistics',
    'World Literature',
    'US Literature',
    'Composition',
    'Chemistry',
    'Physics',
    'Biology',
    'Earth Science',
    'Zoology',
    'Aquatics',
    'Drama',
    'World History',
    'Geography',
    'US History',
    'US Government',
    'Civics',
    'Health',
    'Agriculture',
    'AP Art History',
    'AP Biology',
    'AP Calculus',
    'AP Chem Brief',
    'AP Chemistry',
    'AP Chinese Language and Culture',
    'AP Comparative Government and Politics',
    'AP Computer Science A',
    'AP Computer Science Principles',
    'AP English',
    'AP Environmental Science',
    'AP European History',
    'AP European History (2020)',
    'AP French Language and Culture',
    'AP Human Geography',
    'AP Latin',
    'AP Macroeconomics',
    'AP Microeconomics (2019)',
    'AP Music Theory',
    'AP Physics',
    'AP Psychology',
    'AP Research',
    'AP Seminar',
    'AP Spanish Language and Culture',
    'AP Spanish Literature and Culture',
    'AP Statistics',
    'AP Statistics (2010)',
    'AP U.S. Government and Politics',
    'AP US History',
    'AP World History',
    'AP World History: Modern',
    'Computer Science Principles (2021)',
    'Pre-AP Algebra 1',
    'Pre-AP Biology',
    'Pre-AP English 1',
    'Pre-AP English II',
    'Pre-AP World History and Geography',
];
