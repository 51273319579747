import BadWordsFilter from 'bad-words';

const badWordsFilter = new BadWordsFilter();

// Words to filter
badWordsFilter.addWords('azz');
badWordsFilter.addWords('assh*le');
badWordsFilter.addWords('f*ck');
badWordsFilter.addWords('sh*t');
badWordsFilter.addWords('fucky');
badWordsFilter.addWords('shittiest');
badWordsFilter.addWords('bullshit');

// Words to remove from filter
badWordsFilter.removeWords('anus');
badWordsFilter.removeWords('balls');
badWordsFilter.removeWords('breasts');
badWordsFilter.removeWords('bastard');
badWordsFilter.removeWords('bastards');
badWordsFilter.removeWords('enema');
badWordsFilter.removeWords('fart');
badWordsFilter.removeWords('fanny');
badWordsFilter.removeWords('feces');
badWordsFilter.removeWords('flipping the bird');
badWordsFilter.removeWords('foreskin');
badWordsFilter.removeWords('God');
badWordsFilter.removeWords('knob');
badWordsFilter.removeWords('knobs');
badWordsFilter.removeWords('masochist');
badWordsFilter.removeWords('masturbate');
badWordsFilter.removeWords('masturbates');
badWordsFilter.removeWords('nazi');
badWordsFilter.removeWords('nazis');
badWordsFilter.removeWords('orifice');
badWordsFilter.removeWords('orgasm');
badWordsFilter.removeWords('pecker');
badWordsFilter.removeWords('penis');
badWordsFilter.removeWords('poop');
badWordsFilter.removeWords('porn');
badWordsFilter.removeWords('preteen');
badWordsFilter.removeWords('rautenberg');
badWordsFilter.removeWords('rectum');
badWordsFilter.removeWords('retard');
badWordsFilter.removeWords('sadist');
badWordsFilter.removeWords('schaffer');
badWordsFilter.removeWords('schmuck');
badWordsFilter.removeWords('screw');
badWordsFilter.removeWords('screwing');
badWordsFilter.removeWords('semen');
badWordsFilter.removeWords('sex');
badWordsFilter.removeWords('sexy');
badWordsFilter.removeWords('shemale');
badWordsFilter.removeWords('slag');
badWordsFilter.removeWords('sphencter');
badWordsFilter.removeWords('testicle');
badWordsFilter.removeWords('testicle*');
badWordsFilter.removeWords('turd');
badWordsFilter.removeWords('vagina');
badWordsFilter.removeWords('vagiina');
badWordsFilter.removeWords('vulva');
badWordsFilter.removeWords('zabourah');
badWordsFilter.removeWords('balls');

export default badWordsFilter;
