/**
 * Query keys for react-query hooks
 */

export const QueryKeys = {
    addRoom: 'addRoom',
    allRoomQuestions: 'allRoomQuestions',
    allRoomTopics: 'allRoomTopics',
    allQuestionResponses: 'allQuestionResponses',
    allActivities: 'allActivities',
    allTeamResponses: 'allTeamResponses',
    checkJoinCode: 'checkJoinCode',
    participants: 'participants',
    room: 'room',
    existingActivitySetting: 'existingActivitySetting',
    newResponsePair: 'newResponsePair',
    rankedResponses: 'rankedResponses',
    aggregatedFeedback: 'aggregatedFeedback',
    participantResponse: 'participantResponse',
    allResponsesFeedback: 'allResponsesFeedback',
    allQuestionAttachments: 'allQuestionAttachments',
    filesFromAWS: 'filesFromAWS',
    finalFourParticipantResponse: 'finalFourParticipantResponses',
    finalTwoParticipantResponse: 'finalTwoParticipantResponses',
    championParticipantResponse: 'championParticipantResponses',
    questionReflections: 'questionReflections',
    participantResponseVotes: 'participantResponseVotes',
    stripeSubscriptionStatus: 'stripeSubscriptionStatus',
    stripeRemainingTrialDays: 'stripeRemainingTrialDays',
    participantComments: 'participantComments',
    participantReflections: 'participantReflections',
    activeQuestionSession: 'activeQuestionSession',
    allQuestionSessions: 'allQuestionSessions',
    activeQuestion: 'activeQuestion',
    singleQuestion: 'singleQuestion',
    singleSession: 'singleSession',
    sessionReportData: 'sessionReportData',
    teacherProfileData: 'teacherProfileData',
    sharedQuestions: 'sharedQuestions',
    sharedQuestionTopics: 'sharedQuestionTopics',
    initialAllInPairings: 'initialAllInPairings',
    adminQuestions: 'adminQuestions',
    immersiveReaderToken: 'immersiveReaderToken',
    adminFilesFromAWS: 'adminFilesFromAWS',
    sessionAIFeedback: 'sessionAIFeedback',
    featureFlags: 'featureFlags',
    responseFeedbackStatus: 'responseFeedbackStatus',
};
