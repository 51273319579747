/** Keys used to store values in sessionStorage */
export const SESSION_KEYS = {
    response: 'response',
    responseSubmitted: 'response_submitted',
    participant: 'participant',
    joinCode: 'join_code',
    startIndex: 'start_index',
    prevResponsePairIds: 'prev_response_pair_ids',
    finalFourCompleted: 'final_four_completed',
    championshipCompleted: 'championship_completed',
};
