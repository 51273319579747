export const mathSymbols = [
    // Basic Arithmetic Operations
    { label: 'addition', unicode: 'U+002B', displayName: 'Addition' },
    { label: 'subtraction', unicode: 'U+2212', displayName: 'Subtraction' },
    { label: 'multiplication_2', unicode: 'U+00D7', displayName: 'Multiplication' },
    { label: 'dot_multiplication', unicode: 'U+22C5', displayName: 'Multiplication' },
    { label: 'division_1', unicode: 'U+002F', displayName: 'Division' },
    { label: 'division_2', unicode: 'U+00F7', displayName: 'Division' },
    { label: 'factorial', unicode: 'U+0021', displayName: 'Factorial' },
    { label: 'exponent', unicode: 'U+005E', displayName: 'Exponent' },
    { label: 'square_root', unicode: 'U+221A', displayName: 'Square Root' },
    { label: 'plus_or_minus', unicode: 'U+00B1', displayName: 'Plus or Minus' },

    // Comparison and Equality Symbols
    { label: 'equal_to', unicode: 'U+003D', displayName: 'Equal To' },
    { label: 'not_equal_to', unicode: 'U+2260', displayName: 'Not Equal To' },
    { label: 'greater_than', unicode: 'U+003E', displayName: 'Greater Than' },
    { label: 'less_than', unicode: 'U+003C', displayName: 'Less Than' },
    {
        label: 'greater_than_or_equal_to',
        unicode: 'U+2265',
        displayName: 'Greater Than or Equal To',
    },
    {
        label: 'less_than_or_equal_to',
        unicode: 'U+2264',
        displayName: 'Less Than or Equal To',
    },
    {
        label: 'approximately_equal_to',
        unicode: 'U+2248',
        displayName: 'Approximately Equal To',
    },
    { label: 'congruent_to', unicode: 'U+2245', displayName: 'Congruent To' },
    { label: 'similar_to', unicode: 'U+223C', displayName: 'Similar To' },

    // Advanced Operations
    { label: 'summation', unicode: 'U+2211', displayName: 'Summation' },
    { label: 'product', unicode: 'U+220F', displayName: 'Product' },
    { label: 'integral', unicode: 'U+222B', displayName: 'Integral' },
    { label: 'proportional_to', unicode: 'U+221D', displayName: 'Proportional To' },

    // Set Notation
    { label: 'set_notation_1', unicode: 'U+007B', displayName: 'Set Notation 1' },
    { label: 'set_notation_2', unicode: 'U+007D', displayName: 'Set Notation 2' },
    { label: 'element_of_a_set', unicode: 'U+2208', displayName: 'Element of a Set' },
    { label: 'union_of_sets', unicode: 'U+222A', displayName: 'Union of Sets' },
    {
        label: 'intersection_of_sets',
        unicode: 'U+2229',
        displayName: 'Intersection of Sets',
    },
    { label: 'subset_of_a_set', unicode: 'U+2286', displayName: 'Subset of a Set' },
    { label: 'empty_set', unicode: 'U+2205', displayName: 'Empty Set' },
    {
        label: 'set_of_natural_numbers',
        unicode: 'U+2115',
        displayName: 'Set of Natural Numbers',
    },
    { label: 'set_of_integers', unicode: 'U+2124', displayName: 'Set of Integers' },
    {
        label: 'set_of_rational_numbers',
        unicode: 'U+211A',
        displayName: 'Set of Rational Numbers',
    },
    {
        label: 'set_of_real_numbers',
        unicode: 'U+211D',
        displayName: 'Set of Real Numbers',
    },
    {
        label: 'set_of_complex_numbers',
        unicode: 'U+2102',
        displayName: 'Set of Complex Numbers',
    },

    // Geometry
    { label: 'angle', unicode: 'U+2220', displayName: 'Angle' },
    { label: 'degrees', unicode: 'U+00B0', displayName: 'Degrees' },
    { label: 'parallel', unicode: 'U+2016', displayName: 'Parallel' },
    { label: 'perpendicular', unicode: 'U+22A5', displayName: 'Perpendicular' },
    { label: 'triangle', unicode: 'U+25B3', displayName: 'Triangle' },

    // Calculus
    { label: 'delta', unicode: 'U+0394', displayName: 'Delta' },
    { label: 'theta', unicode: 'U+03B8', displayName: 'Theta' },
    { label: 'prime', unicode: 'U+2032', displayName: 'Prime' },

    // Numbers and Common Constants
    { label: 'pi', unicode: 'U+03C0', displayName: 'Pi' },
    { label: 'euler_s_number', unicode: 'U+0065', displayName: "Euler's Number" },
    { label: 'imaginary_unit', unicode: 'U+0069', displayName: 'Imaginary Unit' },
    {
        label: 'imaginary_unit_engineering',
        unicode: 'U+006A',
        displayName: 'Imaginary Unit (Engineering)',
    },
    { label: 'infinity', unicode: 'U+221E', displayName: 'Infinity' },
    {
        label: 'function_notation_f',
        unicode: 'U+0066',
        displayName: 'Function Notation f',
    },
    { label: 'absolute_value', unicode: 'U+007C', displayName: 'Absolute Value' },

    // Arrows
    { label: 'left_arrow', unicode: 'U+2190', displayName: 'Left Arrow' },
    { label: 'up_arrow', unicode: 'U+2191', displayName: 'Up Arrow' },
    { label: 'right_arrow', unicode: 'U+2192', displayName: 'Right Arrow' },
    { label: 'down_arrow', unicode: 'U+2193', displayName: 'Down Arrow' },
    /**
     * These arrows are not supported by the font we are using
     */
    // { label: 'left_right_arrow', unicode: 'U+2194', displayName: 'Left-Right Arrow' },
    // { label: 'up_down_arrow', unicode: 'U+2195', displayName: 'Up-Down Arrow' },

    // Fractions
    { label: 'fraction_1_4', unicode: 'U+00BC', displayName: 'Fraction 1/4' },
    { label: 'fraction_1_2', unicode: 'U+00BD', displayName: 'Fraction 1/2' },
    { label: 'fraction_3_4', unicode: 'U+00BE', displayName: 'Fraction 3/4' },
    { label: 'fraction_1_7', unicode: 'U+2150', displayName: 'Fraction 1/7' },
    { label: 'fraction_1_9', unicode: 'U+2151', displayName: 'Fraction 1/9' },
    { label: 'fraction_1_10', unicode: 'U+2152', displayName: 'Fraction 1/10' },
    { label: 'fraction_1_3', unicode: 'U+2153', displayName: 'Fraction 1/3' },
    { label: 'fraction_2_3', unicode: 'U+2154', displayName: 'Fraction 2/3' },
    { label: 'fraction_1_5', unicode: 'U+2155', displayName: 'Fraction 1/5' },
    { label: 'fraction_2_5', unicode: 'U+2156', displayName: 'Fraction 2/5' },
    { label: 'fraction_3_5', unicode: 'U+2157', displayName: 'Fraction 3/5' },
    { label: 'fraction_4_5', unicode: 'U+2158', displayName: 'Fraction 4/5' },
    { label: 'fraction_1_6', unicode: 'U+2159', displayName: 'Fraction 1/6' },
    { label: 'fraction_5_6', unicode: 'U+215A', displayName: 'Fraction 5/6' },
    { label: 'fraction_1_8', unicode: 'U+215B', displayName: 'Fraction 1/8' },
    { label: 'fraction_3_8', unicode: 'U+215C', displayName: 'Fraction 3/8' },
    { label: 'fraction_5_8', unicode: 'U+215D', displayName: 'Fraction 5/8' },
    { label: 'fraction_7_8', unicode: 'U+215E', displayName: 'Fraction 7/8' },

    // Subscripts and Superscripts
    { label: 'superscript_0', unicode: 'U+2070', displayName: 'Superscript 0' },
    { label: 'superscript_1', unicode: 'U+00B9', displayName: 'Superscript 1' },
    { label: 'superscript_2', unicode: 'U+00B2', displayName: 'Superscript 2' },
    { label: 'superscript_3', unicode: 'U+00B3', displayName: 'Superscript 3' },
    { label: 'superscript_4', unicode: 'U+2074', displayName: 'Superscript 4' },
    { label: 'superscript_5', unicode: 'U+2075', displayName: 'Superscript 5' },
    { label: 'superscript_6', unicode: 'U+2076', displayName: 'Superscript 6' },
    { label: 'superscript_7', unicode: 'U+2077', displayName: 'Superscript 7' },
    { label: 'superscript_8', unicode: 'U+2078', displayName: 'Superscript 8' },
    { label: 'superscript_9', unicode: 'U+2079', displayName: 'Superscript 9' },
    // Superscript Parentheses
    {
        label: 'superscript_left_parenthesis',
        unicode: 'U+207D',
        displayName: 'Superscript Parenthesis Open',
    },
    {
        label: 'superscript_right_parenthesis',
        unicode: 'U+207E',
        displayName: 'Superscript Parenthesis Close',
    },
    { label: 'subscript_0', unicode: 'U+2080', displayName: 'Subscript 0' },
    { label: 'subscript_1', unicode: 'U+2081', displayName: 'Subscript 1' },
    { label: 'subscript_2', unicode: 'U+2082', displayName: 'Subscript 2' },
    { label: 'subscript_3', unicode: 'U+2083', displayName: 'Subscript 3' },
    { label: 'subscript_4', unicode: 'U+2084', displayName: 'Subscript 4' },
    { label: 'subscript_5', unicode: 'U+2085', displayName: 'Subscript 5' },
    { label: 'subscript_6', unicode: 'U+2086', displayName: 'Subscript 6' },
    { label: 'subscript_7', unicode: 'U+2087', displayName: 'Subscript 7' },
    { label: 'subscript_8', unicode: 'U+2088', displayName: 'Subscript 8' },
    { label: 'subscript_9', unicode: 'U+2089', displayName: 'Subscript 9' },

    // Subscript Parentheses
    {
        label: 'subscript_parentheses_open',
        unicode: 'U+208D',
        displayName: 'Subscript Parentheses Open',
    },
    {
        label: 'subscript_parentheses_close',
        unicode: 'U+208E',
        displayName: 'Subscript Parentheses Close',
    },

    // Greek Letters
    { label: 'alpha', unicode: 'U+03B1', displayName: 'Alpha' },
    { label: 'beta', unicode: 'U+03B2', displayName: 'Beta' },
    { label: 'gamma', unicode: 'U+03B3', displayName: 'Gamma' },
    { label: 'epsilon', unicode: 'U+03B5', displayName: 'Epsilon' },
    { label: 'zeta', unicode: 'U+03B6', displayName: 'Zeta' },
    { label: 'eta', unicode: 'U+03B7', displayName: 'Eta' },
    { label: 'iota', unicode: 'U+03B9', displayName: 'Iota' },
    { label: 'kappa', unicode: 'U+03BA', displayName: 'Kappa' },
    { label: 'lambda', unicode: 'U+03BB', displayName: 'Lambda' },
    { label: 'mu', unicode: 'U+03BC', displayName: 'Mu' },
    { label: 'nu', unicode: 'U+03BD', displayName: 'Nu' },
    { label: 'xi', unicode: 'U+03BE', displayName: 'Xi' },
    { label: 'omicron', unicode: 'U+03BF', displayName: 'Omicron' },
    { label: 'rho', unicode: 'U+03C1', displayName: 'Rho' },
    { label: 'sigma', unicode: 'U+03C3', displayName: 'Sigma' },
    { label: 'tau', unicode: 'U+03C4', displayName: 'Tau' },
    { label: 'upsilon', unicode: 'U+03C5', displayName: 'Upsilon' },
    { label: 'phi', unicode: 'U+03C6', displayName: 'Phi' },
    { label: 'chi', unicode: 'U+03C7', displayName: 'Chi' },
    { label: 'psi', unicode: 'U+03C8', displayName: 'Psi' },
    { label: 'omega', unicode: 'U+03C9', displayName: 'Omega' },

    // Uppercase Greek Letters
    { label: 'Gamma', unicode: 'U+0393', displayName: 'Uppercase Gamma' },
    { label: 'Delta', unicode: 'U+0394', displayName: 'Uppercase Delta' },
    { label: 'Theta', unicode: 'U+0398', displayName: 'Uppercase Theta' },
    { label: 'Lambda', unicode: 'U+039B', displayName: 'Uppercase Lambda' },
    { label: 'Xi', unicode: 'U+039E', displayName: 'Uppercase Xi' },
    { label: 'Pi', unicode: 'U+03A0', displayName: 'Uppercase Pi' },
    { label: 'Sigma', unicode: 'U+03A3', displayName: 'Uppercase Sigma' },
    { label: 'Phi', unicode: 'U+03A6', displayName: 'Uppercase Phi' },
    { label: 'Psi', unicode: 'U+03A8', displayName: 'Uppercase Psi' },
    { label: 'Omega', unicode: 'U+03A9', displayName: 'Uppercase Omega' },
];
