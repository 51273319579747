import badWordsFilter from './badWordsSingleton.js';
import identifyBadPhrases from './badPhrasesFilter.js';
/**
 * This returns a string of identified bad words
 */
const identifyBadWords = (text: string): string[] => {
    // Split the text into words
    const words = text.split(/\b/);

    // Filter out bad words
    const foundBadWords = words.filter((word) => badWordsFilter.isProfane(word));

    // Filter out bad phrases
    const foundBadPhrases = identifyBadPhrases(text);

    // Check for custom bad words not caught by the filter
    const customChecks = ['a$$', 'a$$hole'];
    customChecks.forEach((word) => {
        if (text.toLowerCase().includes(word)) foundBadWords.push(word);
    });

    // Return the list of bad words found
    return [...new Set(foundBadWords), ...foundBadPhrases];
};

export default identifyBadWords;
