import { Room } from '../types/Room.js';

export const hasPremiumSubscription = (room: Room) => {
    return (
        room?.subscription_status === 'active' || room?.subscription_status === 'trialing'
    );
};

export const hasTrialSubscription = (room: Room) => {
    return room?.subscription_status === 'trialing';
};

export const hasActiveSubscription = (room: Room) => {
    return room?.subscription_status === 'active';
};
