import { AttachmentParticipantId } from '../types/Attachment.js';
import { ParticipantResponse } from '../types/Response.js';

/**
 * Get the S3 file key from a participant response obj or AttachmentParticipantId obj
 */
export const getS3ObjectKey = (
    item:
        | ParticipantResponse
        | (Partial<AttachmentParticipantId> & {
              file_name: string;
              participant_id: string;
          }),
    sessionId: string,
) => {
    let fileName = '';

    if (item) {
        if ('attachment_file_name' in item) {
            fileName = item.attachment_file_name;
        } else {
            fileName = item.file_name;
        }
    }

    // SessionId needs to go here so we can delete all files associated with a session
    return `${sessionId}/${item?.participant_id}/${fileName}`;
};
