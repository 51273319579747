export enum AnalyticsEvents {
    playPauseAudioButtonClicked = 'play_pause_audio_button_clicked',
    showHideVotesButtonClicked = 'show_hide_votes_button_clicked',
    showHidePointsButtonClicked = 'show_hide_points_button_clicked',
    showHideCodeNamesButtonClicked = 'show_hide_code_names_button_clicked',
    nextPairButtonClicked = 'next_pair_button_clicked',
    responseAdded = 'response_added',
    responseUpdated = 'response_updated',
    feedbackSubmitted = 'feedback_submitted',
    badWordsDetected = 'bad_words_detected',
    clearRoomButtonClicked = 'clear_room_button_clicked',
    studentClickedWritingScaffold = 'student_clicked_writing_scaffold',
    translateScaffoldTargetLanguageChanged = 'translate_scaffold_target_language_changed',
    // Home
    teacherSignInButtonClicked = 'teacher_sign_in_button_clicked',
    studentSignInButtonClicked = 'student_sign_in_button_clicked',
    // Sign in
    signedIn = 'signed_in',
    // Sign Up
    accountCreated = 'account_created',
    // JOIN
    joinedButtonClicked = 'join_button_clicked',
    newParticipantAdded = 'new_participant_added',
    // Questions
    questionAdded = 'question_added',
    addQuestionButtonClicked = 'add_question_button_clicked',
    preCreatedQuestionButtonClicked = 'precreated_question_button_clicked',
    questionSaved = 'question_saved',
    launchButtonClicked = 'launch_button_clicked',
    editQuestionButtonClicked = 'edit_question_button_clicked',
    questionDuplicated = 'question_duplicated',
    questionDeleted = 'question_deleted',
    attachmentAdded = 'attachment_added',
    addWritingScaffoldsButtonClicked = 'add_writing_scaffolds_button_clicked',
    removeWritingScaffoldsButtonClicked = 'remove_writing_scaffolds_button_clicked',
    precreatedQuestionAdded = 'precreated_question_added',
    multiplePrecreatedQuestionAdded = 'multiple_precreated_question_added',
    // Account
    topicQuestionsShared = 'topic_questions_shared',

    // Reports
    pdfReportDownloaded = 'pdf_report_downloaded',

    // Select Activity
    newActivitySessionStarted = 'new_activity_session_started',
    teacherJoinedSession = 'teacher_joined_session',

    // Payments
    sidebarTryPremiumButtonClicked = 'sidebar_try_premium_button_clicked',
    sidebarUpgradeButtonClicked = 'sidebar_upgrade_button_clicked',
    questionsFreeTrialLinkClicked = 'questions_free_trial_link_clicked',
    planFreeTrialButtonClicked = 'plan_free_trial_button_clicked',
    planManageSubscriptionLinkClicked = 'plan_manage_subscription_link_clicked',
    openCommentsTryPremiumButtonClicked = 'open_comments_try_premium_button_clicked',
    feedbackCriteriaUpdgradeButtonClicked = 'feedback_criteria_upgrade_button_clicked',
    writingScaffoldsUpgradeButtonClicked = 'writing_scaffolds_upgrade_button_clicked',
    pdfReportUpgradeButtonClicked = 'pdf_report_upgrade_button_clicked',

    // Battle Royale
    beginFinalFourButtonClicked = 'begin_final_four_button_clicked',
    moveToFinalRoundButtonClicked = 'move_to_final_round_button_clicked',
    showResultsButtonClicked = 'show_results_button_clicked',
    knockoutRoundVoteSubmitted = 'knockout_round_vote_submitted',
    finalFourVoteSubmitted = 'final_four_vote_submitted',
    championshipVoteSubmitted = 'championship_vote_submitted',
    reflectionSubmitted = 'reflection_submitted',
    feedbackRoundStarted = 'feedback_round_started',
    studentFeedbackSubmitted = 'student_feedback_submitted',
    allInCriteriaBreakdownSelectionChanged = 'all_in_criteria_breakdown_selection_changed',

    // Account Settings
    accountDeleted = 'account_deleted',
    // Sidebar
    resourcesLinkClicked = 'resources_link_clicked',
    supportLinkClicked = 'support_link_clicked',
    communityLinkClicked = 'community_link_clicked',
    signedOut = 'signed_out',
    // Count Responses
    responseDeleted = 'response_deleted',
    studentResponsesSubmitted = 'student_responses_submitted',
    // Select Responses
    showHideNamesButtonClicked = 'show_hide_names_button_clicked',
    // Select Activity Modal
    activityLaunched = 'activity_launched',
    copyPasteSettingModified = 'copy_paste_setting_modified',
    openCommentsSettingModified = 'open_comments_setting_modified',
    immersiveReaderSettingModified = 'immersive_reader_setting_modified',

    // Compare Responses
    neitherButtonClicked = 'neither_button_clicked',

    // End Activity Modal
    responsesDownloaded = 'responses_downloaded',
    activityEnded = 'activity_ended',
}
