export const GRADES = [
    {
        id: 1,
        name: '1st grade',
        value: '1st',
        age: 6,
    },
    {
        id: 2,
        name: '2nd grade',
        value: '2nd',
        age: 7,
    },
    {
        id: 3,
        name: '3rd grade',
        value: '3rd',
        age: 8,
    },
    {
        id: 4,
        name: '4th grade',
        value: '4th',
        age: 9,
    },
    {
        id: 5,
        name: '5th grade',
        value: '5th',
        age: 10,
    },
    {
        id: 6,
        name: '6th grade',
        value: '6th',
        age: 11,
    },
    {
        id: 7,
        name: '7th grade',
        value: '7th',
        age: 12,
    },
    {
        id: 8,
        name: '8th grade',
        value: '8th',
        age: 13,
    },
    {
        id: 9,
        name: '9th grade',
        value: '9th',
        age: 14,
    },
    {
        id: 10,
        name: '10th grade',
        value: '10th',
        age: 15,
    },
    {
        id: 11,
        name: '11th grade',
        value: '11th',
        age: 16,
    },
    {
        id: 12,
        name: '12th grade',
        value: '12th',
        age: 17,
    },
    {
        id: 13,
        name: 'University/Higher Ed',
        value: 'University/Higher Ed',
        age: 18,
    },
];
