export const FEEDBACK = {
    deprecated: [
        { id: '0', feedback: 'clear explanation', score: 5 }, // id:16 is the new version of this criteria
        { id: '1', feedback: 'strong support for ideas', score: 5 }, // id:13 is the new version of this criteria
        { id: '2', feedback: 'creative', score: 5 }, // id:10 is the new version of this criteria
    ],
    content: [
        {
            id: '10',
            feedback: 'Creative',
            description:
                'Uses novel ideas to explore an answer or expand on a writing prompt, introducing thoughts out of the box.',
            score: 5,
        },
        {
            id: '11',
            feedback: 'Strong explanation of content',
            description:
                'Demonstrates a thorough understanding of the topic by offering well-explained, insightful perspectives and examples',
            score: 5,
        },
        {
            id: '12',
            feedback: 'Clear relevance',
            description:
                'Stays focused on the topic, ensuring all information and ideas are directly related to the topic.',
            score: 5,
        },
        {
            id: '13',
            feedback: 'Strong evidence and reasoning',
            description:
                'Uses high-quality evidence and reasoning to make a convincing or compelling argument.',
            score: 5,
        },
        {
            id: '14',
            feedback: 'Engaging word choice ',
            description:
                'Grabs the reader’s attention through lively, interesting word choice',
            score: 5,
        },
        {
            id: '15',
            feedback: 'Unique voice',
            description:
                'Writing contains a unique, clear personal voice or tone, making the writing feel authentic and connected to the writer.',
            score: 5,
        },
    ],
    structure: [
        {
            id: '16',
            feedback: 'Clarity',
            description:
                'Expresses ideas clearly and concisely, ensuring the reader can easily follow the ideas presented.',
            score: 5,
        },
        {
            id: '17',
            feedback: 'Logical organization',
            description:
                'Ideas are shared in a well-structured manner, with a clear progression from introduction to conclusion.',
            score: 5,
        },
        {
            id: '18',
            feedback: 'Clear topic sentence(s)',
            description:
                'Paragraph(s) begins with a clear topic sentence that introduces the main idea clearly',
            score: 5,
        },
        {
            id: '19',
            feedback: 'Transitions',
            description:
                '*Uses smooth transitions between ideas, paragraphs, and sections, helping the writing flow seamlessly.*',
            score: 5,
        },
        {
            id: '20',
            feedback: 'Sentence variety',
            description:
                'Creates a great rhythm using a mix of sentence lengths and structures, making the writing more engaging.',
            score: 5,
        },
        {
            id: '21',
            feedback: 'Accurate writing conventions',
            description: 'Demonstrates strong grammar, punctuation, and spelling',
            score: 5,
        },
    ],
};
