import badWordsFilter from './badWordsSingleton.js';
import identifyBadWords from './identifyBadWords.js';

export const checkForBadWords = (text: string) => {
    if (
        badWordsFilter.isProfane(text) ||
        // These are custom checks not caught by the filter
        text?.toLowerCase() === 'a$$' ||
        text?.toLowerCase() === 'a$$hole'
    ) {
        const badWords = identifyBadWords(text).join(', ');
        // Wrap bad words between forward slashes [/{badwords}/], makes it easy on frontend to extract and highlight
        throw new Error(
            `Hey now...we detected bad words. Delete these bad words: /${badWords}/ and try submitting again.`,
        );
    }

    return text;
};

export default checkForBadWords;
